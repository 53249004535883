import EmailIcon from '@mui/icons-material/Email';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Autocomplete, Backdrop, Skeleton, Stack, TextField } from '@mui/material';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { AccessLevel, BooksOptions, FeeOptions, FilterGroup, UserInfo } from '../../components/AppConstants';
import { BottomNavAction } from '../../components/custom/BottomNavAction';
import { BottomNavBar } from '../../components/custom/BottomNavBar';
import { TextFieldOptional } from '../../components/custom/TextFieldOptional';
import { StudentCard } from './StudentCard';
import { StudentDetailEdit } from './StudentDetailEdit';

export interface Student {
  id: number,
  schoolYear: string,
  firstName: string,
  lastName: string,
  level: string,
  fee: string,
  books: string,
  tamilName: string,
  dob: string,
  gender: string,
  fatherName: string,
  motherName: string,
  fatherEmail: string,
  motherEmail: string,
  fatherPhone: string,
  motherPhone: string,
  primaryContact: string,
  foodAllergy: string,
  emergencyContact: string,
  homeAddress: string,
  createdAt: string,
  updatedAt: string
}

interface StudentPageProps {
  userInfo: UserInfo
  selectedYear: string
  selectedLevel?: string | undefined
  logout: () => void
}

interface StudentFilter {
  label: string
  group: string
}

const StudentFilterOptions: StudentFilter[] = [
  { label: FeeOptions.Paid, group: FilterGroup.Fee },
  { label: FeeOptions.NotPaid, group: FilterGroup.Fee },
  { label: BooksOptions.Collected, group: FilterGroup.Books },
  { label: BooksOptions.NotCollected, group: FilterGroup.Books },
];


export function StudentPage(props: StudentPageProps) {
  const [refresh, setRefresh] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [students, setStudents] = useState<Student[]>([]);
  const [selectedStudent, setSelectedStudent] = useState<Student | undefined>(undefined);
  const [filteredStudents, setFilteredStudents] = useState<Student[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>("")
  const [studentFilters, setStudentFilters] = useState<StudentFilter[]>([])

  async function loadStudents() {
    try {
      setStudents([])
      setFilteredStudents([])
      const suffix = props.selectedLevel ? "&level=" + props.selectedLevel : ""
      const studentsApiUrl = `api/students?year=${props.selectedYear}` + suffix;
      console.log(studentsApiUrl)
      const response = await fetch(studentsApiUrl)
      if (response.ok) {
        const result: Student[] = await response.json();
        setStudents(result)
        setFilteredStudents(result)
        var existingStudentInDb: Student | undefined = undefined
        if (selectedStudent) {
          existingStudentInDb = result.find((student) => student.id === selectedStudent.id);
          setSelectedStudent(existingStudentInDb)
        }
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("No students found.")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setLoading(false)
  }

  useEffect(() => {
    loadStudents();
  }, [props.selectedYear, props.selectedLevel, refresh])

  function onPersonClick(student: Student | undefined): void {
    setSelectedStudent(student)
  }

  function onStudentSaved() {
    enqueueSnackbar('Student saved successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    setRefresh(!refresh)
  }

  function onStudentDeleted() {
    enqueueSnackbar('Student deleted successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    setRefresh(!refresh)
  }

  function addStudentHandler(): void {
    const newStudent: Student = {
      id: -1,
      schoolYear: props.selectedYear,
      firstName: '',
      lastName: '',
      level: '',
      fee: 'NotPaid',
      books: 'NotCollected',
      primaryContact: 'Mother',
      fatherName: '',
      motherName: '',
      fatherEmail: '',
      motherEmail: '',
      fatherPhone: '',
      motherPhone: '',
      tamilName: '',
      dob: '',
      gender: 'Male',
      emergencyContact: '',
      foodAllergy: '',
      homeAddress: '',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    }
    setSelectedStudent(newStudent)
  }

  useEffect(() => {
    const firstFiltered = students.filter((student) => {
      let match = true
      for (const studentFilter of studentFilters) {
        if (studentFilter.group === FilterGroup.Fee) {
          match = match && student.fee === studentFilter.label
        } else if (studentFilter.group === FilterGroup.Books) {
          match = match && student.books === studentFilter.label
        }
      }
      return match
    })

    const secondFiltered = firstFiltered.filter((student) => {
      return searchKeyword === ""
        || student.firstName.toLowerCase().includes(searchKeyword.toLowerCase())
        || student.lastName.toLowerCase().includes(searchKeyword.toLowerCase())
    })

    setFilteredStudents(secondFiltered)
  }, [searchKeyword, studentFilters])

  const handleDownload = async () => {
    const suffix = props.selectedLevel ? "&level=" + props.selectedLevel : ""
    const downloadUrl = `api/students/download?year=${props.selectedYear}` + suffix;
    console.log(downloadUrl)
    try {
      const response = await fetch(downloadUrl);
      console.log(response)
      const csvData = await response.text();

      const contentDisposition = response.headers.get('Content-Disposition');
      const filenameMatch = contentDisposition && contentDisposition.match(/filename=(.+)/);
      const filename = filenameMatch ? filenameMatch[1] : 'students.csv';

      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading data:', error);
    }
  }

  const handleEmail = async () => {
    const suffix = props.selectedLevel ? "?level=" + props.selectedLevel : ""
    const emailUrl = `api/students/email?year=${props.selectedYear}` + suffix;
    console.log(emailUrl)
    try {
      const response = await fetch(emailUrl, {
        method: 'POST',
      })

      if (response.ok || response.status === 201) {
        console.log("Email report sent successfully.")
        enqueueSnackbar('Email report sent successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Failed to send email report.")
        enqueueSnackbar('Failed to send email report.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Failed to send email report.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
  }

  return (
    <Stack height='100%'>
      <SnackbarProvider />
      {/* This Backdrop extend the container to max screen height.
      BattomBar position issues in android browser due to auto url bar hiding */}
      <Backdrop open={false} />
      {loading &&
        <Stack gap={2} padding={2}>
          <Skeleton variant="rectangular" height={130} />
          <Skeleton variant="rectangular" height={130} />
          <Skeleton variant="rectangular" height={130} />
        </Stack>
      }
      {!loading && selectedStudent &&
        <StudentDetailEdit
          userInfo={props.userInfo}
          allLevels={props.userInfo.level}
          student={selectedStudent}
          onBackClick={() => onPersonClick(undefined)}
          onDeleteClick={onStudentDeleted}
          onSaveClick={onStudentSaved}
          logout={props.logout} />
      }
      {!loading && !selectedStudent &&
        <Stack height='100%' padding={2} paddingBottom={10} gap={2}>
          <TextFieldOptional
            label="Search"
            placeholder="Search by student's first name or last name"
            value={searchKeyword}
            baseValue={searchKeyword}
            onChange={setSearchKeyword}
          />
          {props.selectedLevel === undefined &&
            <Autocomplete
              multiple
              id='studentFilter'
              options={StudentFilterOptions}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.label}
              value={studentFilters}
              onChange={(_event, newValue) => {
                console.log('onChange', newValue)
                setStudentFilters(newValue)
              }}
              renderInput={(params) => <TextField {...params} label="Filter" />}
              renderOption={(props, option) => <li {...props} key={option.label}>{option.label}</li>}
            />
          }
          {
            filteredStudents.map((student, index) =>
              <StudentCard
                key={index}
                index={index + 1}
                student={student}
                allLevels={props.selectedLevel === undefined}
                onPersonClick={onPersonClick} />)
          }
        </Stack>
      }
      {!loading && !selectedStudent && props.userInfo.studentInfoAccess === AccessLevel.ReadWrite &&
        <BottomNavBar>
          <BottomNavAction label="Email" icon={<EmailIcon />} onClick={handleEmail} />
          <BottomNavAction label="Download" icon={<FileDownloadIcon />} onClick={handleDownload} />
          <BottomNavAction label="Add" icon={<PersonAddIcon />} onClick={addStudentHandler} />
        </BottomNavBar>
      }
    </Stack>
  );
}